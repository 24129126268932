import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/900.css"

import logo from "../assets/images/icons/logo.svg"

import Nav from "../components/nav/Nav"
import BackToTopButton from "../components/backToTopButton/BackToTopButton"
import ProgressBar from "../components/progressBar/ProgressBar"
import "../colors.scss"
import "../main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Nav />
      <main>{children}</main>
      <section id="contact">
        <footer className="footer">
          <img className="logo" src={logo} alt="logo" />
          <p style={{ textTransform: "uppercase", fontWeight: "600" }}>
            Worker home sp z o.o.
          </p>
          <p>
            <span>Nip: </span>534-263-53-71
          </p>
          <p>
            <span>KRS: </span>0000883845
          </p>
          <p>
            <span>Email: </span>
            <a href="mailto:kontakt@workerhome.pl">kontakt@workerhome.pl</a>
          </p>
          <p>
            <span>Telefon Artur: </span>
            <a href="tel:+48609994992">+48 609 994 992</a>
          </p>
          <p>
            <span>Telefon Marcin: </span>
            <a href="tel:+48501744323">+48 501 744 323</a>
          </p>

          <div className="copyright">
            © {new Date().getFullYear()}{" "}
            <a target="blank" href="https://www.barejastudio.pl">
              barejastudio.pl
            </a>
          </div>
        </footer>
      </section>

      <BackToTopButton />
      <ProgressBar />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
